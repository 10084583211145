import EntityBaseRepository from '@/api/repositories/EntityBaseRepository';
import {CancelToken, CancelTokenSource} from 'axios';
import Job, { JobColor } from '@/models/Job';
import WorkSession from '@/models/WorkSession';
import {FindAllResponse} from '@/interfaces/Responses';

export default class JobRepository extends EntityBaseRepository {

    private cancelTokenSourceOption: { cancelToken: CancelToken | undefined } = {cancelToken: undefined};
    private cancelTokenSource!: CancelTokenSource;

    constructor() {
        super();
        this.cancelTokenSource = this.cancelTokenFactory.source();
        this.cancelTokenSourceOption.cancelToken = this.cancelTokenSource.token;
    }

    public loadJobs(queryData: string): Promise<Job[]> {
        return this.axiosClient.get(`/analytics/jobSummary?${queryData}`, this.cancelTokenSourceOption);
    }

    public loadWorkSession(workSessionId: string): Promise<any> {
        return this.axiosClient.get(`/workSession/${workSessionId}`, this.cancelTokenSourceOption);
    }

    public getWorkSessions(queryString: string): Promise<FindAllResponse<WorkSession>> {
        return this.axiosClient.get(`/workSession${queryString}`, this.cancelTokenSourceOption);
    }

    public createWorkSession(workSession: WorkSession): Promise<any> {
        if (workSession.user) {
            workSession.userId = workSession.user.id!;
        }
        return this.axiosClient
            .post(`worksession?user=${workSession.userId}&cleanTime=${workSession.cleanTime ?? workSession.cleanTimeId}&cleanTimeOccurrence=${workSession.cleanTimeOccurrence}`
                , workSession);
    }

    public updateWorkSession(workSession: WorkSession): Promise<any> {
        return this.axiosClient.patch(`/worksession/${workSession.id}`, workSession);
    }

    public saveJobColors(payload: JobColor[], companyId: string): Promise<FindAllResponse<JobColor>> {
        return this.axiosClient.put(`/colorCode/many?companyId=${companyId}`, payload);
    }

    public getJobColors(companyId: string): Promise<FindAllResponse<JobColor>> {
        return this.axiosClient.get(`/colorCode?companyId=${companyId}`);
    }

    // TODO: find out the new route for images
    public getSessionACTTImage(workSessionId: string, acttId: string, imageId: string, thumbnail: boolean): Promise<any> {
        const thumbnailQuery = thumbnail ? '?thumbnail' : '';
        return this.axiosClient.get(
            `/workSession/${workSessionId}/ACTT/${acttId}/sessionACTT/images/${imageId}${thumbnailQuery}`,
            {...this.cancelTokenSourceOption, responseType: 'blob'});
    }
    public getWorkSessionImage(payload: {workSessionId: string, imageId: string, thumbnail: boolean}): Promise<{blob: Blob}> {
        const thumbnailQuery = payload.thumbnail ? '?thumbnail=true' : '';
        return this.axiosClient.get(`/workSession/${payload.workSessionId}/images/${payload.imageId}${thumbnailQuery}`,
            {...this.cancelTokenSourceOption, responseType: 'blob'});
    }

    public cancelRequests() {
        this.cancelTokenSource.cancel('cancelled by ui');
        this.cancelTokenSource = this.cancelTokenFactory.source();
        this.cancelTokenSourceOption.cancelToken = this.cancelTokenSource.token;
    }

}
